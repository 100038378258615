<template>
  <div class="course-container">
    <el-breadcrumb separator="/" class="breadcrumb2">
      <el-breadcrumb-item>课程管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 表格数据 -->
    <el-card class="course-table">
      <i class="el-icon-tickets"></i>
      <span>课程列表</span>
      <el-table
        :data="signData"
        border
        stripe
        style="width: 100%; height: auto"
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <el-table-column
          label="序号"
          type="index"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column prop="name" label="课程名称" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.name }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="week" label="周数" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.week }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="section" label="课程时间" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.section }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="signStopType" label="签到方式" align="center">
          <template slot-scope="scope">
            <p>
              {{ scope.row.signStopType == 1 ? '手动控制签到' : '倒计时签到' }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          sortable
          label="创建时间"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.createtime }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="address" width="300" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              plain
              type="warning"
              size="mini"
              @click="handleApply(scope.$index, scope.row)"
              >加入组织</el-button
            >
            <el-button
              plain
              type="danger"
              size="mini"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next,jumper"
          :current-page.sync="formInline.pageNum"
          :page-size="formInline.pageSize"
          :page-sizes="[5, 10, 15]"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>

    <!--加入组织-->
    <el-dialog :visible.sync="dialogApllyVisible" width="60%">
      <div class="search-container">
        <span class="search">组织码</span>
        <el-input
          v-model="searchCode"
          placeholder="请输入申请加入的组织码"
          style="width:240px;"
          size="small"
          suffix-icon="el-icon-search"
          @keydown.native.enter="searchOrganization"
        >
        </el-input>
        <el-button type="primary" size="mini" @click="searchOrganization"
          >搜索</el-button
        >
      </div>
      <div class="search-result">
        <el-table :data="organizationSearch" fit border stripe>
          <el-table-column
            prop="ocode"
            label="组织码"
            align="center"
          ></el-table-column>
          <el-table-column
            label="组织管理员姓名"
            prop="name"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="oname"
            label="组织单位名称"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" width="180" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="applyToJoin(scope.row)"
                type="warning"
                plain
                >申请</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeApplyDialog">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import qs from 'qs'
import Cookies from 'js-cookie'
const defaultFormInline = {
  pageNum: 1,
  pageSize: 5,
  university: null,
  academy: null,
  name: null
}
export default {
  name: 'signList',
  data() {
    return {
      signlist: [],
      signData: [],
      formInline: {
        university: '',
        academy: '',
        name: '',
        pageNum: 1,
        pageSize: 5
      },
      tid: null, //用户id
      uid: null, //用户id
      // 分页
      total: 0,
      dialogApllyVisible: false,
      searchCode: '', // 用户输入的组织码
      organizationSearch: [], //查找的组织
      ccid: '' //课程id
    }
  },
  created() {
    var toCode = window.sessionStorage.getItem('toCode')
    if (toCode == 'true') {
      this.$confirm('是否跳转到当前签到活动？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          window.sessionStorage.setItem('toCode', false)
          var lid = window.sessionStorage.getItem('lid')
          var endTime = window.sessionStorage.getItem('loginEndTime')
          var state = window.sessionStorage.getItem('loginState')
          this.$router.push({
            // path: '/signList/signlinkList/signCode',
            path: '/mycourse/courseList/signlinkList/signCode',
            query: { lid: lid, EndTime: endTime, status: state }
          })
        })
        .catch(() => {})
    }

    this.tid = window.sessionStorage.getItem('uid')
    this.uid = window.sessionStorage.getItem('uid')
    // this.tid = this.$route.query.tid
    window.sessionStorage.setItem('tid', this.tid)

    this.selectList()
  },
  mounted: function() {},
  methods: {
    //处理删除课程操作
    handleDelete(index, row) {
      this.$confirm('确定删除这个课程吗？', '提示', {
        confirmButtonText: '确实',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          try {
            const { data: res } = await this.$http.post(
              '/cou/deletecou',
              qs.stringify({
                ccid: row.ccid
              })
            )
            if (res.code == 200) {
              this.$message.success(res.message)
              this.selectList()
            } else {
              this.$message.error(res.message)
            }
          } catch (error) {
            console.error(error)
            this.$message.error('删除失败，请稍后重试')
          }
        })
        .catch(() => {
          this.$message.info('您已取消操作')
        })
    },

    // 实现分页
    // 更改了每页大小
    handleSizeChange(val) {
      this.formInline.pageNum = 1
      this.formInline.pageSize = val

      // 请求对应的分页数据
      this.selectList()
    },
    // 更改了第几页
    handleCurrentChange(val) {
      this.formInline.pageNum = val
      this.selectList()
    },
    //将课程加入组织
    handleApply(index, row) {
      this.ccid = row.ccid
      this.dialogApllyVisible = true
    },
    // 查询我的课程
    async selectList() {
      const { data: res } = await this.$http.get('/cou/findCouByuid', {
        params: {
          uid: this.uid,
          pageNum: this.formInline.pageNum,
          pageSize: this.formInline.pageSize
        }
      })
      if (res != null) {
        this.signData = res.data
        this.total = res.total
      }
    },
    //搜索
    async searchOrganization() {
      try {
        const { data: res } = await this.$http.get('/org/selectbyocode', {
          params: {
            ocode: this.searchCode
          }
        })
        if (res.code == 200) {
          this.organizationSearch = [res.data]
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      } catch (error) {
        this.$message.error('搜索失败,请稍后重试')
      }
    },
    //申请
    async applyToJoin(row) {
      try {
        const { data: res } = await this.$http.post(
          '/cou/couapplygroup',
          qs.stringify({
            oid: row.oid,
            ccid: this.ccid
          })
        )
        if (res.code == 200) {
          this.$message.success(res.message)
          this.dialogApllyVisible = false
        } else {
          this.$message.error(res.message)
        }
      } catch (err) {
        this.$message.error('申请加入失败，重重试')
      }
    },
    //关闭弹框
    closeApplyDialog() {
      this.dialogApllyVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
// body {
//   padding-right: 0px !important;
//   overflow: hidden;
// }
.course-container {
  padding: 20px 20px 0 20px;
  .course-table {
    margin: 20px 20px 20px 20px;
  }
  // .input-width {
  //   width: 203px;
  // }
  // .home-breadcrumb {
  //   position: relative;
  //   top: -52px;
  //   left: 29px;
  // }
  .el-dialog {
    box-sizing: border-box;
  }
  .el-dialog__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
  .el-dialog__header {
    margin: 0;
    padding: 0;
  }
  .search-container {
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    .search {
      color: #409eff;
      font-size: 25px;
      font-weight: bold;
      margin-right: 5px;
      align-items: center;
    }
    .el-button {
      font-size: 16px;
    }
  }
}
</style>
